<template>
  <b-form @submit.prevent="">
    <hs-group>
      <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.form.teacher.form.name') }}</label>
      <hs-input
        type="text"
        v-model="form.full_name"
        :disabled="isSavingTeacher"
        :placeholder="$t('sparkmembers.sales-page.info.form.teacher.form.name-placeholder')"
        @blur="$v.form.full_name.$touch()"
      />
      <hs-form-invalid-feedback :state="!$v.form.full_name.$error">
        <span class="font-size-xs" v-if="!$v.form.full_name.required">
          {{ $t('validations.required') }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <hs-group>
      <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.form.teacher.form.about') }}</label>
      <hs-text-editor
        v-model="form.about"
        :initialContent="form.about || ''"
        :disabled="isSavingTeacher"
        :placeholder="$t('sparkmembers.sales-page.info.form.teacher.form.about-placeholder')"
        @onUpdate="event => (form.about = event.getHTML())"
      />
    </hs-group>
    <hs-group>
      <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.form.teacher.form.photo') }}</label>
      <div class="d-flex flex-wrap mt-3">
        <div class="position-relative mr-2" v-if="form.photo_url">
          <div class="position-absolute w-100 text-right">
            <hs-icon class="text-primary mr-2" variant="solid" icon="check-circle" />
          </div>
          <b-img-lazy height="91" width="91" class="border-primary rounded-lg border-2" :src="form.photo_url" />
        </div>
        <label
          for="img-teacher-input"
          class="d-flex flex-column justify-content-center rounded-lg border-2 border-dashed border-primary uploader"
          @dragover.prevent
          @drop.prevent="onFile"
        >
          <div class="text-center" v-if="isUploading">
            <b-spinner small type="grow" variant="primary" />
          </div>
          <template v-else>
            <div class="font-size-xs text-center mb-1">
              {{ $t('sparkmembers.sales-page.info.form.teacher.form.upload') }}
            </div>
            <hs-icon class="text-primary text-center font-size-lg" variant="light" icon="cloud-upload" />
          </template>
          <input id="img-teacher-input" type="file" class="d-none" @input="onFile" />
        </label>
      </div>
    </hs-group>
    <div class="d-flex w-100 justify-content-end mt-5">
      <hs-button
        :variant="$v.form.$invalid || isSavingTeacher ? 'light' : 'primary'"
        :disabled="$v.form.$invalid || isSavingTeacher"
        type="submit"
        @click="onTeacherSubmit"
      >
        <b-spinner small type="grow" v-if="isSavingTeacher" />
        {{ $t(`sparkmembers.sales-page.info.form.teacher.${isSavingTeacher ? 'saving' : 'save'}`) }}
      </hs-button>
    </div>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { productService, uploadService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isUploading: false,
      isSavingTeacher: false,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
  },
  methods: {
    async onFile(e) {
      this.isUploading = true;
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
      const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);

      uploadService
        .upload(data.url, data.fields, file)
        .then(url => {
          this.form.photo_url = url;
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.upload.error'));
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    async onTeacherSubmit() {
      this.isSavingTeacher = true;

      const teacherPromise = !this.form.id
        ? productService.createProductTeachers(this.schoolId, this.form)
        : productService.updateProductTeacher(this.form);

      teacherPromise
        .then(res => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.teacher.saved'));

          this.$emit(
            'input',
            !this.form.id
              ? res.data
              : {
                  id: this.form.id,
                  about: this.form.about,
                  full_name: this.form.full_name,
                  photo_url: this.form.photo_url,
                }
          );
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.teacher.save-error'));
        })
        .finally(() => {
          this.isSavingTeacher = false;
        });
    },
  },
  validations: {
    form: {
      full_name: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.border-2 {
  border: 2px solid transparent;
}

.border-dashed {
  border-style: dashed;
}

.uploader {
  width: 91px;
  height: 91px;
}
</style>
